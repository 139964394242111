import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Link } from './ui';

const StyledBreadcrumbs = styled.div`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 25px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledList = styled.ol`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
`;

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.medium};
  color: ${brandColours.primary};
  ${fontSize(12)};
  white-space: nowrap;

  ${minBreakpointQuery.small`
    ${fontSize(13)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(14)};
  `}

  &:before {
    content: '';
    display: block;
    margin: 0 10px 0 4px;
    height: 6px;
    width: 6px;
    border: 2px solid ${brandColours.primary};
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
  }

  &:first-child {
    &:before {
      content: none;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Breadcrumbs = ({
  currentPageTitle,
  items = [],
  isBlogSingle,
  ...props
}) => {
  items.unshift({ text: 'Home', urlPath: '/' });
  items.push({ text: currentPageTitle });

  return (
    items && (
      <>
        <Helmet
          script={[
            {
              type: 'application/ld+json',
              innerHTML: `{
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  ${items.map(({ text, urlPath }, i) =>
                    JSON.stringify({
                      '@type': 'ListItem',
                      position: i + 1,
                      name: text,
                      ...(urlPath && {
                        item:
                          urlPath === '/'
                            ? process.env.GATSBY_SITE_URL
                            : `${process.env.GATSBY_SITE_URL}/${urlPath}/`,
                      }),
                    })
                  )}
                ]
              }`,
            },
          ]}
        />
        <StyledBreadcrumbs {...props}>
          <Container narrow={isBlogSingle}>
            <StyledList>
              {items.map(({ text, urlPath }, i) => (
                <StyledItem key={`breadcrumb-${i}`}>
                  {urlPath ? (
                    <StyledLink to={urlPath}>{text}</StyledLink>
                  ) : (
                    text
                  )}
                </StyledItem>
              ))}
            </StyledList>
          </Container>
        </StyledBreadcrumbs>
      </>
    )
  );
};

export default Breadcrumbs;
