import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import ContentFeaturedPoints from '../components/ContentFeaturedPoints';
import ModularBlocks from '../components/ModularBlocks';
import ActivitiesIconList from '../components/ActivitiesIconList';

const ActivityPageTemplate = ({
  data: {
    datoCmsActivity: {
      seoMetaTags,
      title,
      bannerImage,
      bannerText,
      bannerLink,
      introHeading,
      introText,
      benefits,
      modularBlocks,
    },
    allDatoCmsActivity,
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner
        heading={title}
        image={bannerImage}
        text={bannerText}
        link={bannerLink}
      />
      <Breadcrumbs
        currentPageTitle={title}
        items={[
          {
            urlPath: 'activities',
            text: 'Activities',
          },
        ]}
      />
      <ContentFeaturedPoints
        overline="Intro"
        heading={introHeading}
        subheading="Benefits:"
        text={introText}
        points={JSON.parse(benefits)}
      />
      <hr />
      <ModularBlocks items={modularBlocks} isActivityPage={true} />
    </main>
    <ActivitiesIconList
      overline="Group Activities"
      heading="Other Activities"
      items={allDatoCmsActivity.nodes}
    />
  </Layout>
);

export const ActivityPageTemplateQuery = graphql`
  query ActivityPageTemplateQuery($id: String!) {
    datoCmsActivity(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...SubPageBannerImageFragment
      }
      bannerText
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      introHeading
      introText
      benefits
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImagesContentModularBlockFragment
        ...InternalVideoModularBlockFragment
      }
    }
    allDatoCmsActivity(filter: { id: { ne: $id } }) {
      nodes {
        id
        icon {
          url
          alt
        }
        title
        slug
      }
    }
  }
`;

export default ActivityPageTemplate;
