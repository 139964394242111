import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
} from '../styles';
import { Container, SectionHeader, Button } from './ui';
import IconLinkList from './IconLinkList';
import grassBg from '../images/grass-bg.svg';

const StyledActivitiesIconList = styled.section`
  background-color: ${standardColours.lightGrey};
  background: linear-gradient(
    to bottom,
    ${standardColours.lightGrey} 70%,
    ${brandColours.quaternary} 70%,
    ${brandColours.quaternary} 100%
  );
`;

const StyledOuter = styled.div`
  position: relative;
  ${sectionPaddings()};
  background: url('${grassBg}') no-repeat -100px -120px / 180px;

  ${minBreakpointQuery.small`
    background-position-y: -100px;
  `}

  ${minBreakpointQuery.large`
    background-position-y: -80px;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 20px;
    background-color: ${standardColours.white};
    border-radius: 50%;
    transform: translateY(-50%);
  }
`;

const StyledTop = styled.div`
  margin: 30px 0;

  ${minBreakpointQuery.small`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.mlarge`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  `}

  ${minBreakpointQuery.large`
    margin-top: 50px;
  `}
`;

const StyledButton = styled(Button)`
  ${minBreakpointQuery.mlarge`
    margin-left: 15px;
    white-space: nowrap;
  `}
`;

const StyledItems = styled(IconLinkList)`
  display: grid;
  gap: 20px 30px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.xlarge`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const ActivitiesIconList = ({ overline, heading, items }) => (
  <StyledActivitiesIconList>
    <StyledOuter>
      <Container>
        <StyledTop>
          <SectionHeader overline={overline} heading={heading} />
          <StyledButton to="activities" alt={true}>
            See all activities
          </StyledButton>
        </StyledTop>
        <StyledItems
          items={items}
          parentSlug="activities"
          isActivitiesIconList={true}
        />
      </Container>
    </StyledOuter>
  </StyledActivitiesIconList>
);

export default ActivitiesIconList;
